import axios from "axios";
import React from "react";
import "./App.scss";

class App extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            enabled: true
        };
    }

    onUnlockClick() {
        this.setState({enabled: false})
        axios.request({
            method: "POST",
            baseURL: process.env.REACT_APP_VAULT_URL,
            url: process.env.REACT_APP_VAULT_UNLOCK_PATH,
            data: {
                user: "guest"
            }
        }).then((response) => {

        }).finally((error) => {
            this.setState({enabled: true})
        });
    }

    render() {
        return (
            <div className="app">
                <h3 className="mb-5">Dimitrija Tucovića 66</h3>
                <button className="btn btn-primary btn-lg" onClick={this.onUnlockClick.bind(this)} disabled={!this.state.enabled}>OTVORI</button>
            </div>
        );
    }
}

export default App;
